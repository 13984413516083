.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: White;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: 003d4c;
 
  

}

.accordion-collapse {
  margin-top: 20px;
  margin-bottom: 20px;
  
  }


.accordion-header >.accordion-button::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E");
  transition: all 0.5s;
}
.accordion-header >.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E");
}
.accordion-header >.accordion-button::after {
  transition: all 0.5s;
}




.App-link {
  color: #61dafb;
}

body{margin-top:20px;
  background:#DCDCDC;
  }
  .pricing-content{position:relative;}
  .pricing_design{
      position: relative;
      margin: 0px 15px;
  }
  .pricing_design .single-pricing{
      background:#554c86;
      padding: 60px 40px;
      border-radius:30px;
      box-shadow: 0 10px 40px -10px rgba(0,64,128,.2);
      position: relative;
      z-index: 1;
  }
  .pricing_design .single-pricing:before{
      content: "";
      background-color: #fff;
      width: 100%;
      height: 100%;
      border-radius: 18px 18px 190px 18px;
      border: 1px solid #eee;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: -1;
  }

  .pricing_design .single-pricing:hover{
    transform: scale(1.1);
    background-color :#003d4c;

  }
  .price-head{}
  .price-head h2 {
    margin-bottom: 20px;
    font-size: 26px;
    font-weight: 600;
  }
  .price-head h1 {
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 5px;
  }
  .price-head span{}
  
  .single-pricing ul{list-style:none;margin-top: 30px;}
  .single-pricing ul li {
    line-height: 36px;
  }
  .single-pricing ul li i {
    background: #A71930;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 30px;
    font-size: 11px;
    text-align: center;
    line-height: 20px;
    margin-right: 6px;
  }
  .pricing-price{}
  
  .price_btn {
    background: #A71930;
    padding: 10px 30px;
    color: #fff;
    display: inline-block;
    margin-top: 20px;
    border-radius: 2px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  
  .price_btn:hover{background:#0aa1d6;}
  a{
  text-decoration:none !important;    
  }
  
  .section-title {
      margin-bottom: 60px;
  }
  .text-center {
      text-align: center!important;
  }
  
  .section-title h2 {
      font-size: 45px;
      font-weight: 600;
      margin-top: 0;
      position: relative;
      text-transform: capitalize;
  }

  body{margin-top:20px;
    background:#DCDCDC;
    }
    .pricing-content{position:relative;}
    .pricing_design{
        position: relative;
        margin: 0px 15px;
    }
    .pricing_design .single-pricing{
      background: #A71930;
        padding: 60px 40px;
        border-radius:30px;
        box-shadow: 0 10px 40px -10px rgba(0,64,128,.2);
        position: relative;
        z-index: 1;
    }
    .pricing_design .single-pricing:before{
        content: "";
        background-color: #fff;
        width: 100%;
        height: 100%;
        border-radius: 18px 18px 190px 18px;
        border: 1px solid #eee;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
    .price-head{}
    .price-head h2 {
      margin-bottom: 20px;
      font-size: 26px;
      font-weight: 600;
    }
    .price-head h1 {
      font-weight: 600;
      margin-top: 30px;
      margin-bottom: 5px;
    }
    .price-head span{}
    
    .single-pricing ul{list-style:none;margin-top: 30px;}
    .single-pricing ul li {
      line-height: 36px;
    }
    .single-pricing ul li i {
      background: #A71930;
      color: #fff;
      width: 20px;
      height: 20px;
      border-radius: 30px;
      font-size: 11px;
      text-align: center;
      line-height: 20px;
      margin-right: 6px;
    }
    .pricing-price{}
    
    .price_btn {
      background: #A71930;
      padding: 10px 30px;
      color: #fff;
      display: inline-block;
      margin-top: 20px;
      border-radius: 2px;
      -webkit-transition: 0.3s;
      transition: 0.3s;
    }
    .price_btn:hover{background:#003d4c;}
    a{
    text-decoration:none;    
    }
    
    .section-title {
        margin-bottom: 30px;
    }
    .text-center {
        text-align: center!important;
    }
    
    .section-title h2 {
        font-size: 45px;
        font-weight: 600;
        margin-top: 0;
        position: relative;
        text-transform: capitalize;
    }
div.default-margin{
  margin-bottom: 30px;
}
   

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
